<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :on-export="exportData"
                :collapse-height="60" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="85px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <el-form-item label="校友会名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入" clearable @keyup.enter.native="ok" />
        </el-form-item>
        <el-form-item label="所属架构：" prop="pid">
          <el-select v-model="pid" placeholder="请选择" clearable>
            <el-option v-for="item in pidList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属层级：" prop="level">
          <el-select v-model="level" placeholder="请选择" clearable>
            <el-option v-for="item in levelList" :key="item.level" :label="item.level_name" :value="item.level" />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    pidList: {
      type: Array,
    },
    levelList: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    exportData() {
      // TODO: 导出数据
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    pid: {
      get() {
        return this.form.pid === -1 ? "" : this.form.pid;
      },
      set(val) {
        this.form.pid = val;
      },
    },
    level: {
      get() {
        return this.form.level === -1 ? "" : this.form.level;
      },
      set(val) {
        this.form.level = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
