var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-page"},[(_vm.tenantVersion === 'school')?_c('list-layout',{ref:"schoolLayout",attrs:{"filter-form":_vm.schoolForm,"thead":_vm.schoolHead,"on-fetch":_vm.getOrganizationList,"on-delete":_vm.handleDelete},on:{"count":function($event){_vm.listCount = $event},"command":_vm.handleMore},scopedSlots:_vm._u([{key:"reminder",fn:function(){return [_c('reminder',{attrs:{"text-arr":[
          '如需删除组织架构数据，删除前需转移会员数据',
          '组织架构被删除后，所属的会内职务也将被删除',
          '目前仅支持创建 1 个校友会数据' ]}})]},proxy:true},{key:"top",fn:function(){return [_c('el-button',{staticClass:"top-btn",attrs:{"disabled":_vm.listCount > 0,"type":"primary","size":"small"},on:{"click":_vm.handleAddFrame}},[_vm._v("新增")]),_c('el-button',{staticClass:"top-btn",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.handleCustomForm()}}},[_vm._v("配置全局认证资料")])]},proxy:true},{key:"filter",fn:function(){return [_c('school-list-filter',{attrs:{"filterForm":_vm.schoolForm,"pid-list":_vm.organizationList,"level-list":_vm.levelList,"uploadFilter":_vm.doFilter}})]},proxy:true},{key:"item_is_open_join_template",fn:function(ref){
        var row = ref.row;
return [_c('p',{style:(row.is_open_join_template ? 'color: rgb(19, 206, 102);' : '')},[_vm._v(" "+_vm._s(row.is_open_join_template ? "有" : "无")+" ")])]}},{key:"item_fields_text",fn:function(ref){
        var row = ref.row;
return [_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(row.fields_text))])]}}],null,false,1886508890)}):_c('list-layout',{ref:"listLayout",attrs:{"thead":_vm.thead,"filter-form":_vm.filterForm,"on-fetch":_vm.getOrganizationList,"on-delete":_vm.handleDelete},on:{"command":_vm.handleMore},scopedSlots:_vm._u([{key:"reminder",fn:function(){return [_c('reminder',{attrs:{"title":"温馨提示","text-arr":_vm.tips}})]},proxy:true},{key:"top",fn:function(){return [_c('el-button',{staticClass:"top-btn",attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleAddFrame}},[_vm._v("新增 ")])]},proxy:true},{key:"filter",fn:function(ref){
        var idList = ref.idList;
        var pageData = ref.pageData;
return [_c('list-filter',{attrs:{"levelList":_vm.levelList,"organizationList":_vm.organizationList,"ids":idList,"page":pageData.page,"uploadFilter":_vm.ok},model:{value:(_vm.filterForm),callback:function ($$v) {_vm.filterForm=$$v},expression:"filterForm"}})]}},{key:"item_is_open_join_template",fn:function(ref){
        var row = ref.row;
return [_c('p',{style:(row.is_open_join_template ? 'color: rgb(19, 206, 102);' : '')},[_vm._v(" "+_vm._s(row.is_open_join_template ? "有" : "无")+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }