import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取架构列表
export const OrganizationList = data => {
  return api({
    url: "/admin/cyc/Organization/index",
    method: "post",
    data
  });
};

// 永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc/Organization/del",
    method: "post",
    data
  });
};

// 获取架构层级
export const allLevels = data => {
  return api({
    url: "/admin/cyc/Organization/allLevels",
    method: "post",
    data
  });
};

// 获取所属组织列表
export const parentToList = data => {
  return api({
    url: "/admin/cyc/Organization/parentToList",
    method: "post",
    data
  });
};

//组织架构列表导出
export const documentOrganizationList = data => {
  return fetchFile({
    url: "/admin/cyc/Organization/export",
    method: "post",
    data,
    download: true
  });
};