<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="120" :toggleCollapseEnable="false"
    :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="架构名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入架构名称"></el-input>
        </el-form-item>
        <el-form-item label="申请状态：" prop="has_template">
          <el-select v-model="has_template" placeholder="请选择申请状态">
            <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属架构：" prop="pid">
          <el-select v-model="pid" placeholder="请选择所属架构">
            <el-option v-for="item in organization_list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="架构层级：" prop="level">
          <el-select v-model="level" placeholder="请选择架构层级">
            <el-option v-for="item in level_list" :key="item.level" :label="item.level_name"
              :value="item.level"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { documentOrganizationList } from "../../api/org-frame/frame-list";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    levelList: {
      type: [Array, Object],
    },
    organizationList: {
      type: [Array, Object],
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      statusList: [
        { name: "无", id: 0 },
        { name: "有", id: 1 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter({...this.form, page: 1});
    },
    // 导出组织架构列表
    onExport() {
      const postData = { ...this.form, ids: this.ids, page: this.page };
      return documentOrganizationList(postData).catch(() => {});
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    has_template: {
      get() {
        return this.form.has_template == -1 ? "" : this.form.has_template;
      },
      set(val) {
        this.form.has_template = val;
      },
    },
    pid: {
      get() {
        return this.form.pid == -1 ? "" : this.form.pid;
      },
      set(val) {
        this.form.pid = val;
      },
    },
    level: {
      get() {
        return this.form.level == -1 ? "" : this.form.level;
      },
      set(val) {
        this.form.level = val;
      },
    },
    level_list() {
      return this.levelList;
    },
    organization_list() {
      return this.organizationList;
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
