<template>
  <div class="list-page">
    <list-layout
      v-if="tenantVersion === 'school'"
      ref="schoolLayout"
      :filter-form="schoolForm"
      :thead="schoolHead"
      :on-fetch="getOrganizationList"
      :on-delete="handleDelete"
      @count="listCount = $event"
      @command="handleMore"
    >
      <template #reminder>
        <reminder
          :text-arr="[
            '如需删除组织架构数据，删除前需转移会员数据',
            '组织架构被删除后，所属的会内职务也将被删除',
            '目前仅支持创建 1 个校友会数据',
          ]"
        />
      </template>
      <template v-slot:top>
        <el-button
          :disabled="listCount > 0"
          class="top-btn"
          type="primary"
          size="small"
          @click="handleAddFrame"
          >新增</el-button
        >
        <el-button
          class="top-btn"
          type="primary"
          size="small"
          @click="handleCustomForm()"
          >配置全局认证资料</el-button
        >
      </template>
      <template v-slot:filter>
        <school-list-filter
          :filterForm="schoolForm"
          :pid-list="organizationList"
          :level-list="levelList"
          :uploadFilter="doFilter"
        />
      </template>
      <template #item_is_open_join_template="{ row }">
        <!-- 申请状态 -->
        <p
          :style="row.is_open_join_template ? 'color: rgb(19, 206, 102);' : ''"
        >
          {{ row.is_open_join_template ? "有" : "无" }}
        </p>
      </template>
      <!-- 申请资料 -->
      <template #item_fields_text="{ row }">
        <span style="white-space: pre">{{ row.fields_text }}</span>
      </template>
    </list-layout>
    <list-layout
      v-else
      ref="listLayout"
      :thead="thead"
      :filter-form="filterForm"
      :on-fetch="getOrganizationList"
      :on-delete="handleDelete"
      @command="handleMore"
    >
      <template v-slot:reminder>
        <reminder title="温馨提示" :text-arr="tips" />
      </template>
      <template #top>
        <el-button
          class="top-btn"
          type="primary"
          size="small"
          @click="handleAddFrame"
          >新增
        </el-button>
      </template>
      <template #filter="{ idList, pageData }">
        <list-filter
          v-model="filterForm"
          :levelList="levelList"
          :organizationList="organizationList"
          :ids="idList"
          :page="pageData.page"
          :uploadFilter="ok"
        />
      </template>
      <template #item_is_open_join_template="{ row }">
        <!-- 申请状态 -->
        <p
          :style="row.is_open_join_template ? 'color: rgb(19, 206, 102);' : ''"
        >
          {{ row.is_open_join_template ? "有" : "无" }}
        </p>
      </template>
    </list-layout>
  </div>
</template>

<script>
import listFilter from "../../components/OrgFrame/ListFilter";
import Reminder from "../../../common/components/Reminder";
import {
  OrganizationList,
  parentToList,
  Delete,
  allLevels,
} from "../../api/org-frame/frame-list";
import ListLayout from "../../../../base/layout/ListLayout";
import SchoolListFilter from "../../components/OrgFrame/SchoolListFilter";
import query from "@/base/mixins/query";
export default {
  mixins: [query],
  data() {
    const isVisible = (row) => {
      return !!row.is_open_join_template;
    };
    return {
      mixin__sessionStorageKey: "query_frame_list",
      tips: [
        "如需删除组织架构数据，删除前需转移会员数据",
        "组织架构被删除后，所属的会内职务也将被删除",
      ],
      schoolHead: [
        { type: "selection" },
        { label: "ID", prop: "id", minWidth: 100 },
        {
          label: "Logo",
          prop: "icon",
          type: "icon",
          iconWidth: 30,
          borderRadius: "50%",
        },
        { label: "校友会名称", prop: "name", minWidth: 150 },
        { label: "描述", prop: "description", minWidth: 250 },
        { label: "上级", prop: "parent_name", minWidth: 250 },
        { label: "所属层级", prop: "level_name", minWidth: 250 },
        {
          label: "校友人数",
          prop: "members_count",
          minWidth: 120,
          sortable: true,
        },
        { label: "申请状态", prop: "is_open_join_template", type: "slot" },
        { label: "申请资料", prop: "fields_text", type: "slot", minWidth: 200 },
        {
          label: "操作",
          type: "operation",
          width: 220,
          operations: [
            { command: "edit" },
            { text: "删除", command: "delete" },
            { text: "配置认证资料", command: "verify" },
          ],
        },
      ],
      thead: [
        { type: "selection" },
        { label: "ID", prop: "id" },
        {
          label: "图标",
          prop: "icon",
          type: "icon",
          iconWidth: 30,
          borderRadius: "50%",
        },
        { label: "架构名称", prop: "name", width: 130 },
        { label: "描述", prop: "description", width: 250 },
        { label: "所属架构", prop: "parent_name", width: 150 },
        { label: "所属层级", prop: "level_name" },
        {
          label: "会员人数",
          prop: "members_count",
          width: 130,
          sortable: true,
        },
        { label: "申请状态", prop: "is_open_join_template", type: "slot" },
        {
          label: "操作",
          type: "operation",
          operations: [
            { command: "edit" },
            { text: "删除", command: "delete" },
            {
              text: "更多",
              command: "more",
              operations: [{ command: "form", text: "配置个人申请信息" }],
              visible: isVisible,
            },
          ],
          width: 150,
        },
      ],
      schoolForm: {
        keyword: "",
        pid: -1, // 所属架构id
        level: -1, // 层级
        page_size: 15, //每页多少条数据
      },
      // 筛选对象
      filterForm: {
        level: -1, // 层级
        has_template: -1, //申请状态
        pid: -1, // 所属架构id
        keyword: "", //架构名称
        page_size: 15, //每页数据数
      },
      levelList: [], // 架构层级
      organizationList: [], // 所属架构选项列表
      pageData: {
        page_size: 15,
      },
      tbody: [],
      srcList: [],
      listCount: 0,
    };
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  methods: {
    // 自定义申请跳转
    handleCustomForm(pageset = "user") {
      let routerData = this.$router.resolve({
        name: "FormPage",
        params: { id: 0, pageset: pageset },
      });
      window.open(routerData.href, "_blank");
    },
    handleMore({ command: e, row, pageData }) {
      switch (e) {
        case "edit":
          this.editRow(row, pageData);
          break;
        case "verify":
        case "form":
          let routerData = this.$router.resolve({
            name: "FormPage",
            params: { id: row.id, pageset: "user" },
          });
          window.open(routerData.href, "_blank");
          break;
      }
    },
    // 获取列表
    getOrganizationList(pageData) {
      return OrganizationList(pageData);
    },
    // 获取架构层级
    getAllLevels() {
      allLevels()
        .then((res) => {
          this.levelList = res.data;
        })
        .catch(() => {});
    },
    // 获取所属组织列表
    getParentToList() {
      parentToList({ crr_level: -1 })
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch(() => {});
    },
    // 新增架构
    handleAddFrame() {
      this.$router.push({
        name: "AddFrame",
        query: { id: 0, type: "add" },
      });
    },
    // 数据删除
    handleDelete(id) {
      return Delete({ id });
    },
    // 点击编辑
    editRow(row, pageData) {
      this.pageData = pageData;
      const routeName = "AddFrame";
      this.mixin__beforeLeaveList(routeName);
      this.$router.push({
        name: "AddFrame",
        query: { id: row.id, type: "edit" },
      });
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList({ ...this.filterForm, ...pageData });
    },
    doFilter(e) {
      const pageData = {};
      this.schoolForm = { ...this.schoolForm, ...e, page: 1 };
      this.$refs.schoolLayout.getList({ ...this.schoolForm, ...pageData });
    },
    getList(f) {
      if (this.tenantVersion === "school") {
        this.schoolForm = { ...this.schoolForm, ...f };
      } else {
        this.filterForm = { ...this.filterForm, ...f };
      }
    },
  },
  created() {
    this.getAllLevels();
    this.getParentToList();
  },
  components: { SchoolListFilter, ListLayout, listFilter, Reminder },
};
</script>

<style lang="scss" scoped>
.link-btn {
  text-decoration: none;
  color: #fff;
}
.list-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 50%;
}

.list-page {
}
</style>
